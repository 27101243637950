import React from 'react';
import { Container } from './styles';

import logoImg from '../../assets/logo_priori_max.png';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { TiSocialInstagram } from 'react-icons/ti';
import { TiSocialFacebook } from 'react-icons/ti';
import { TiSocialLinkedin } from 'react-icons/ti';

const Footer: React.FC = () => {
  return (
    <>
      <Container>
        <div className="one">
          <div className="logo-footer">
            <img src={logoImg} alt="logo" />
          </div>
          <div className="address">
            <h3>Endereço</h3>
            <p>Avenida T-63, Nº 984, Salas 213, 214, 221 e 222</p>
            <p>Setor Bueno, Goiânia-Goiás &#8211; CEP: 74.230-100</p>
          </div>
          <div className="contact">
            <h3>Contato</h3>
            <p>
              <BsFillTelephoneFill />
              (62) 4102 - 2108
            </p>
          </div>
        </div>

        <div className="two">
          <div className="title">
            <h3>Redes Sociais</h3>
          </div>

          <div className="social_network-logo">
            <span>
              <a
                href="https://www.instagram.com/grupopriorimax/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TiSocialInstagram />
              </a>
            </span>
            <span>
              <a
                href="https://www.facebook.com/grupopriorimax"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TiSocialFacebook />
              </a>
            </span>
            <span>
              <a
                href="https://www.linkedin.com/company/priori-max/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TiSocialLinkedin />
              </a>
            </span>
          </div>
        </div>

        <div className="three">
          <p className="copyrights">
            &copy; Copyrights <span>Priori Max</span> todos os direitos
            reservados.
          </p>
          <p className="developer">
            <span>Desenvolvido por </span>
            <span>
              <a
                href="https://www.linkedin.com/in/virgiliocano/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Virgílio Cano
              </a>
            </span>
          </p>
        </div>
      </Container>
    </>
  );
};

export default Footer;
