import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  body {
    font-family: 'Poppins', sans-serif;
    background: #fff;
    color: #000;
  }

  button {
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  
  
  ul {
    list-style: none;
  }

  .whatsapp_link {
    position: fixed;
    bottom: 5rem;
    right: 10rem;
    z-index: 100;

    @media screen and (max-width: 768px) {
      right: unset;
      left: 5rem;
    }
    
    .whatsapp_icon {
      width: 8rem;
      height: 8rem;
      color:#25d366;
      box-shadow: 0.1rem 0.1rem 0.1rem #999;

      @media screen and (max-width: 768px) {
        width: 7rem;
        height: 7rem;
      }
    }
  }
`;

export const MainContainer = styled.main`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;
