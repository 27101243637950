import { MenuItem } from '../interfaces/menuItem';

export const menuItems: MenuItem[] = [
  { name: 'Home', path: '/' },
  { name: 'Quem Somos', path: '/quem-somos' },
  {
    name: 'Serviços',
    path: '#',
    subMenuItems: [
      {
        name: 'Trinitas',
        path: 'https://trinitasgestao.com.br/',
      },
      {
        name: 'GMTS',
        path: 'https://www.gmtsadvogados.com/',
      },
      {
        name: 'Integra DP',
        path: 'http://www.integradp.com.br/',
      },
      {
        name: 'ForYou',
        path: 'http://www.foryoulancamentos.com.br/',
      },
      {
        name: 'Domax',
        path: 'http://www.domaxsolucoes.com.br/',
      },
    ],
  },
  { name: 'Registro de Marca', path: '/registro-de-marca' },
];
