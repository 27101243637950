import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10rem 20rem;

  @media screen and (max-width: 1395px) {
    padding: 8rem 10rem;
  }

  @media screen and (max-width: 1395px) {
    padding: 5rem 2.5rem;
  }

  @media screen and (max-width: 768px) {
    padding: 2rem;
  }

  @media screen and (max-width: 540px) {
    padding: unset;
  }

  .highlight {
    font-weight: bold;
  }

  .time-wasted,
  .growth-help {
    margin-bottom: 15rem;

    @media screen and (max-width: 768px) {
      margin-bottom: 10rem;
    }

    @media screen and (max-width: 540px) {
      margin-bottom: 5rem;
    }
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    img {
      width: 40%;
      margin-right: 2rem;

      @media screen and (max-width: 768px) {
        width: 60%;
        margin-right: unset;
      }

      @media screen and (max-width: 540px) {
        width: 90%;
      }
    }

    p {
      font-size: 2.5rem;
      width: 60%;
      text-align: justify;
      padding: 3rem;

      @media screen and (max-width: 1310px) {
        font-size: 2.2rem;
      }

      @media screen and (max-width: 1050px) {
        font-size: 2rem;
      }

      @media screen and (max-width: 768px) {
        font-size: 1.8rem;
        width: 80%;
      }

      @media screen and (max-width: 768px) {
        font-size: 1.5rem;
        width: 100%;
      }
    }
  }

  .growth-help p {
    margin-right: 2rem;

    @media screen and (max-width: 768px) {
      margin-right: unset;
    }
  }
`;
