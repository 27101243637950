import React from 'react';
import { MainContainer } from '../../styles/globalStyles';
import { Container } from './styles';

const RegistroDeMarca: React.FC = () => {
  return (
    <>
      <MainContainer>
        <Container>
          <p>
            Você pode ter o melhor ponto comercial, um site bonito e colocar seu
            logo nas redes sociais, em banners e outdoors. Mas atenção: se não
            registrou a marca, ela ainda não é sua. E pior, pode ser registrada
            por outro a qualquer momento e fazer você perder muito dinheiro.
            Calma. É possível evitar que isso aconteça!
          </p>

          <h1>O que é marca?</h1>
          <p>
            A jornada de um empreendedor passa por muitas tarefas para garantir
            o sucesso da abertura de uma empresa. Entre elas, o Registro de
            Marca, uma etapa que muitos profissionais postergam, ignorando como
            isso pode proteger o seu negócio. Seja qual for a sua área de
            atuação, cuidar da marca é um passo fundamental para garantir mais
            impacto junto aos consumidores e mais resultados no final do mês.
          </p>

          <p>
            Tecnicamente, a marca é “um sinal visualmente perceptível, que gera
            diferenciação de produtos e serviços no mercado”, é o que distingue
            você da concorrência e faz do seu produto ou serviço, algo único.
          </p>

          <p>
            A finalidade do Registro de Marca é justamente protegê-la contra
            fraudes e plágios, fortalecendo a empresa, facilitando a comunicação
            e em muitos casos, criando laços afetivos do consumidor com a sua
            marca preferida.
          </p>

          <p>
            Além disso, o Registro de Marca pode se transformar em um ativo, ou
            seja, um investimento rentável, já que a marca pode ser licenciada e
            se transformar em uma fonte direta de renda por meio de recebimento
            de royalties.
          </p>

          <h1>
            Existem quatro formas de apresentação da marca aceitas pelo
            Instituto:
          </h1>
          <p>
            <span className="highlight">Marca nominativa:</span> tipo de marca
            composta apenas pelo nome, usando para isso uma ou mais palavras,
            expressões e combinações de letras e/ou números.
          </p>

          <p>
            <span className="highlight">Marca figurativa:</span> como o nome
            sugere, este tipo de marca possui apenas desenhos, símbolos, ícones,
            imagens e figuras isoladas e/ou em conjunto.
          </p>

          <p>
            <span className="highlight">Marca mista:</span> aqui é possível
            combinar elementos de texto e figuras, ou seja, unir palavras,
            letras e números a elementos gráficos. Neste caso, o Registro de
            Marca protege tanto o nome quanto o logotipo.
          </p>

          <p>
            <span className="highlight">Marca tridimensional:</span> o principal
            objetivo deste tipo de registro é proteger a forma e/ou layout, ou
            seja, a aparência única de um produto ou de uma embalagem. Assim, o
            titular garante o direito de uso exclusivo daquela forma específica,
            em todo o território nacional, e, consequentemente, o direito de
            evitar o lançamento de embalagens idênticas.
          </p>

          <h1>Etapas do Processo do Registro de Marca</h1>
          <p>
            Depois da pesquisa, as principais etapas do processo para registrar
            a Marca são: depósito, publicação, deferimento e concessão do
            registro.
          </p>

          <p>
            Primeiro é preciso organizar e apresentar os documentos exigidos
            pelo instituto, além de emitir e efetuar o pagamento da Guia de
            Recolhimento da União (GRU). Mas isso ainda não garante seu
            registro.
          </p>

          <p>
            Após ser protocolado, o pedido de Registro de Marca será submetido
            ao exame formal para continuidade do processo. Serão verificadas no
            exame o pagamento da retribuição relativa ao pedido de registro e
            inconsistências ou incorreções nos dados apresentados.
          </p>

          <p>
            Se estiver tudo certo e não houver nenhuma exigência formal, o
            pedido de registro é publicado. Neste momento, mais uma vez, o
            acompanhamento de um profissional experiente é valioso. Ele deverá
            acompanhar rigorosamente o andamento do pedido, já que nesta etapa
            abre-se o prazo para outras empresas se manifestarem caso decidam
            que a sua marca pode prejudicá-las. Responder ou não à esta oposição
            pode ser decisivo na aprovação do seu pedido de registro.
          </p>

          <p>
            Uma vez deferido, ou seja, aprovado, o Registro de Marca é expedido
            em formato digital, sendo assegurado ao titular a exclusividade do
            uso em todo o território nacional pelo período de dez anos. Depois
            disso, para não correr o risco de ter o seu Registro de Marca
            extinto pelo INPI, é preciso solicitar sua renovação durante o
            penúltimo ano de vigência do registro, ou seja, durante o nono ano a
            contar da data da concessão do registro. E, assim, deverá
            prorrogá-lo, sucessivamente, a cada década.
          </p>

          <p>
            Além disso, caso a marca não seja usada por mais de cinco anos, você
            também pode perder o registro e todos os direitos sobre ela. É o que
            se chama de “caducidade”. A legislação entende que não pode existir
            “reserva de marca”. Ou seja, mesmo que esteja registrada no INPI, se
            a marca não for utilizada após cinco anos da data de concessão e
            houver interesse de outra empresa, ela poderá ser declarada caduca,
            com a consequente extinção, liberando-a para que terceiros requeiram
            seu registro, causando prejuízos incalculáveis ao antigo titular.
          </p>
        </Container>
      </MainContainer>
    </>
  );
};

export default RegistroDeMarca;
