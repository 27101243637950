import styled from 'styled-components';

export const Container = styled.footer`
  margin-top: auto;

  background: #004128;
  color: #fff;
  display: block;

  .one {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5rem 5rem 0 5rem;

    @media (max-width: 680px) {
      flex-direction: column-reverse;
    }

    .logo-footer img {
      width: 30rem;

      @media (max-width: 900px) {
        width: 14rem;
      }

      @media (max-width: 500px) {
        width: 10rem;
      }
    }

    .address,
    .contact {
      @media (max-width: 680px) {
        text-align: center;
      }
    }

    .address h3,
    .contact h3 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }

    .address p {
      font-size: 1.5rem;
    }

    .contact {
      @media (max-width: 680px) {
        margin-bottom: 2rem;
      }

      p {
        font-size: 1.8rem;

        @media (max-width: 680px) {
          margin-right: 1rem;
        }
      }

      svg {
        padding-top: 0.5rem;
        margin-right: 0.5rem;

        width: 2rem;
        height: 2rem;
      }
    }
  }

  .two {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    .title h3 {
      font-size: 1.8rem;
    }

    .social_network-logo {
      display: flex;
      gap: 1rem;

      a svg {
        width: 4rem;
        height: 4rem;
      }
    }
  }

  .three {
    margin-top: 3rem;
    background: #f1ebe0;
    color: #000;
    font-weight: bold;
    padding: 2rem;
    display: flex;
    justify-content: space-evenly;
    height: 3rem;

    @media (max-width: 500px) {
      flex-direction: column;
      padding: 8rem 3rem 3rem 3rem;
    }

    .copyrights span,
    .developer a {
      color: #db5b1f;
    }

    .copyrights,
    .developer {
      @media (max-width: 500px) {
        text-align: center;
      }
    }

    .developer {
      @media (max-width: 500px) {
        margin-top: 2rem;
      }
    }
  }
`;
