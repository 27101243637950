import { Container } from './styles';

import desktop_hero_banner from '../../assets/banner_desktop.jpg';
import mobile_hero_banner from '../../assets/banner_mobile.jpg';

export function HeroBanner() {
  return (
    <Container>
      <div className="hero-banner">
        <img
          className="desktop_hero-image"
          src={desktop_hero_banner}
          alt="Desktop Banner"
        />
        <img
          className="mobile_hero-image"
          src={mobile_hero_banner}
          alt="mobile Banner"
        />
      </div>
    </Container>
  );
}
