import styled from 'styled-components';

export const Container = styled.div`
  .hero-banner {
    position: relative;
    z-index: -1;
  }

  .desktop_hero-image,
  .mobile_hero-image {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 4rem;
  }

  .mobile_hero-image {
    display: none;
  }

  @media (max-width: 768px) {
    .desktop_hero-image {
      display: none;
    }

    .mobile_hero-image {
      display: block;
    }
  }

  @media (max-width: 600px) {
    .mobile_hero-image {
      margin-top: 9rem;
    }
  }
`;
