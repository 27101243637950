import styled from 'styled-components';

export const Container = styled.div`
  background: #004128;
  color: #fff;

  .nav {
    display: flex;
    align-items: center;
    font-size: 2rem;
    justify-content: space-around;
    height: 15vh;
  }

  #logo {
    width: 15rem;

    @media (max-width: 900px) {
      width: 14rem;
    }
  }

  .nav__menu {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 3rem;
  }

  .nav__toggler {
    display: none;
  }

  .nav__toggler div {
    width: 2.5rem;
    height: 0.2rem;
    margin: 0.4rem;
    background: rgb(204, 204, 204);
    transition: 0.3s ease-in;
  }

  .nav_item {
    position: relative;
    display: block;
    float: left;
    padding: 1rem;
    transition-duration: 0.3s;
  }

  .nav_item:hover {
    cursor: pointer;
    background: #fff;
    color: #004128;
  }

  .nav__menu .nav_item ul {
    visibility: hidden;
    opacity: 0;
    min-width: 5rem;
    position: absolute;
    transition: all 0.5s ease;
    margin-top: 1rem;
    left: 0;
    display: none;
  }

  .nav__menu .nav_item:hover > ul,
  .nav__menu .nav_item ul:hover {
    visibility: visible;
    opacity: 1;
    display: block;
    background: #fff;
    z-index: 1;
  }

  .nav__menu .nav_item:hover > ul,
  .nav__menu .nav_item ul li {
    clear: both;
    width: 100%;
  }

  .nav__menu .nav_item ul li {
    padding: 0.5rem 1rem 0 1rem;
    font-weight: bold;
  }

  .nav__menu .nav_item ul li:hover {
    background: #004128;
    color: #fff;
    font-weight: unset;
    padding-bottom: 0.75rem;
    text-decoration: underline solid #fff 0.2rem;
  }

  @media screen and (max-width: 768px) {
    .nav {
      position: fixed;
      background: #004128;
      width: 100vw;
      text-align: center;
    }

    .nav__toggler {
      display: block;
      cursor: pointer;
    }

    .nav__menu {
      position: fixed;
      top: 7vh;
      right: 0;
      height: 93vh;
      width: 50vw;
      background: #004128;
      flex-direction: column;
      transform: translateX(100%);
      transition: 0.5s ease-in;
      padding: 5rem 0;
    }
  }

  @media screen and (max-width: 540px) {
    .nav {
      font-size: 1.8rem;
    }

    .nav__menu {
      height: 100vh;
      padding: 10rem 2rem 10rem 0;
      width: 55vw;
    }
  }

  @media screen and (max-width: 395px) {
    .nav {
      width: 110vw;
    }

    .nav__menu {
      width: 50vw;
    }
  }

  /* Active Class */
  .nav__active {
    transform: translateX(0%);
  }

  /* Toggle Icon Animation */

  .toggle .line1 {
    transform: rotate(-45deg) translate(-4px, 5px);
  }
  .toggle .line2 {
    opacity: 0;
  }
  .toggle .line3 {
    transform: rotate(45deg) translate(-4px, -5px);
  }
`;
