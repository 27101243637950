import { Container } from './styles';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { menuItems } from '../../utils/menuItems';
import { MenuItem } from '../../interfaces/menuItem';
import logoImg from '../../assets/logo_priori_max.png';

export const MenuNavBar: React.FC = () => {
  const [activeItem, setActiveItem] = useState('');
  const [active, setActive] = useState('nav__menu');
  const [icon, setIcon] = useState('nav__toggler');

  const navToggle = () => {
    if (active === 'nav__menu') {
      setActive('nav__menu nav__active');
    } else setActive('nav__menu');

    // Icon Toggler
    if (icon === 'nav__toggler') {
      setIcon('nav__toggler toggle');
    } else setIcon('nav__toggler');
  };

  const handleMouseEnter = (item: MenuItem) => {
    setActiveItem(item.name);
  };

  const handleMouseLeave = () => {
    setActiveItem('');
  };

  const renderSubMenuItems = (subMenuItems: MenuItem[]) => {
    return (
      <ul className="nav__submenu--items">
        {subMenuItems.map((subMenuItem, index) => (
          <li key={index} className="nav__submenu--item">
            <a
              href={subMenuItem.path}
              target="_blank"
              rel="noopener noreferrer"
            >
              {subMenuItem.name}
            </a>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Container>
      <nav className="nav">
        <Link to="/" className="nav_brand">
          <img id="logo" src={logoImg} alt="logo" />
        </Link>
        <ul className={active}>
          {menuItems.map((menuItem, index) => (
            <li
              key={index}
              onMouseEnter={() => handleMouseEnter(menuItem)}
              onMouseLeave={handleMouseLeave}
              className="nav_item"
            >
              <Link to={menuItem.path} className="nav__link">
                {menuItem.name}
              </Link>
              {menuItem.subMenuItems &&
                activeItem === menuItem.name &&
                renderSubMenuItems(menuItem.subMenuItems)}
            </li>
          ))}
        </ul>
        <div onClick={navToggle} className={icon}>
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </nav>
    </Container>
  );
};
