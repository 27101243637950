import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10rem;

  @media screen and (max-width: 768px) {
    margin-top: 10rem;
    padding: 8rem 5rem;
  }

  @media screen and (max-width: 540px) {
    padding: 8rem 1.5rem;
  }

  @media screen and (max-width: 415px) {
    padding: 8rem 1rem;
  }

  p {
    font-size: 2rem;
    text-align: justify;
    margin-bottom: 1.5rem;
    padding: 0 2.5rem;

    @media screen and (max-width: 415px) {
      font-size: 1.6rem;
    }
  }

  h1 {
    margin-top: 2rem;
    margin-bottom: 0.8rem;

    @media screen and (max-width: 540px) {
      margin-left: 1.5rem;
    }
  }

  .highlight {
    font-weight: bold;
  }
`;
