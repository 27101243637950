import React from 'react';
import { MainContainer } from '../../styles/globalStyles';
import { Container } from './styles';

const QuemSomos: React.FC = () => {
  return (
    <>
      <MainContainer>
        <Container>
          <p className="whoweare">
            Um grupo de empresas gerado no contexto empresarial brasileiro com
            foco em suporte ao escritório contábil, integrando experiência e
            inteligência com o objetivo de fomentar novos negócios para o
            contador, otimizar o tempo da sua equipe de trabalho e oferecer
            segurança para a prestação de serviço contábil com mais eficiência.
          </p>

          <div className="line"></div>

          <p className="vision">
            <span className="highlight">Visão: </span>
            Ser reconhecido como o principal provedor de soluções B2B,
            oferecendo um portfólio diversificado de serviços de alta qualidade
            e valor agregado para nossos clientes, impulsionando o sucesso e a
            excelência em todos os setores em que atuamos.
          </p>

          <p className="mission">
            <span className="highlight">Missão: </span>
            Nossa missão é facilitar o crescimento e o sucesso dos nossos
            clientes, fornecendo serviços inovadores e sob medida, combinados
            com expertise e excelência em cada segmento de negócio em que
            atuamos. Nosso objetivo é estabelecer parcerias de longo prazo,
            baseadas na confiança, colaboração e resultados excepcionais.
          </p>

          <p className="values">
            <span className="highlight">Valores: </span> Excelência, Inovação,
            Integridade, Colaboração, Orientação ao cliente, Sustentabilidade,
            Transparência.
          </p>
        </Container>
      </MainContainer>
    </>
  );
};

export default QuemSomos;
