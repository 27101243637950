import styled from 'styled-components';

export const Container = styled.div`
  p {
    padding: 10rem;
    font-size: 3rem;
    text-align: justify;

    @media screen and (max-width: 768px) {
      font-size: 2rem;
    }

    @media screen and (max-width: 540px) {
      font-size: 1.7rem;
      padding: 10rem 2.5rem 2.5rem 2.5rem;
    }

    @media screen and (max-width: 395px) {
      font-size: 1.5rem;
    }
  }

  .line {
    width: 80%;
    margin: 0 auto;
    border-bottom: 1px solid #004128;
  }

  .whoweare {
    @media screen and (max-width: 768px) {
      margin-top: 10rem;
    }
  }

  .vision,
  .mission,
  .values {
    padding: 2.5rem 10rem;

    .highlight {
      font-weight: bold;
      color: #004128;
    }

    @media screen and (max-width: 540px) {
      padding: 2.5rem;
    }
  }

  .values {
    padding-bottom: 5rem;
  }
`;
