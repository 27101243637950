import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { GlobalStyle } from './styles/globalStyles';
import Footer from './components/Footer';

import { MenuNavBar } from './components/MenuNavBar';

import Home from './pages/Home';
import RegistroDeMarca from './pages/Registro-De-Marca';
import QuemSomos from './pages/Quem-Somos';

import { FaWhatsappSquare } from 'react-icons/fa';

const App: React.FC = () => {
  return (
    <>
      <a
        href="http://wa.me/+556241022108"
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp_link"
      >
        <FaWhatsappSquare className="whatsapp_icon" />
      </a>

      <GlobalStyle />
      <MenuNavBar />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/quem-somos" element={<QuemSomos />} />
        <Route path="/registro-de-marca" element={<RegistroDeMarca />} />
      </Routes>

      <Footer />
    </>
  );
};

export default App;
