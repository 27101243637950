import React from 'react';
import { HeroBanner } from '../../components/HeroBanner';
import { MainContainer } from '../../styles/globalStyles';

import { Container } from './styles';

import timeWastedImage from '../../assets/home/timeWasted.jpeg';
import growthHelp from '../../assets/home/growth-help.jpeg';
import solution from '../../assets/home/solution.jpeg';

const Home: React.FC = () => {
  return (
    <>
      <MainContainer>
        <HeroBanner />

        <Container>
          <div className="time-wasted">
            <img src={timeWastedImage} alt="time wasted" />
            <p>
              Dificuldades financeiras, falta de visibilidade no mercado e
              burocracias diárias. Sabemos das dores que sua empresa enfrenta
              todos os dias, mas não precisa enfrentá-las sozinho. Estamos aqui
              para ajudar a superar esses desafios e impulsionar o seu
              crescimento.
            </p>
          </div>

          <div className="growth-help">
            <img src={growthHelp} alt="growth help" />
            <p>
              Imagine <span className="highlight">sua empresa prosperando</span>{' '}
              em um cenário altamente competitivo. Com nossa parceria
              estratégica, você terá acesso a
              <span className="highlight"> soluções personalizadas</span>, com
              ganho de tempo, redução de custos, otimização de processos e
              crescimento da carteira de clientes. Para melhor atendê-lo temos
              uma equipe dedicada a{' '}
              <span className="highlight">impulsionar o seu sucesso</span> e
              juntos, podemos transformar desafios em oportunidades reais.
            </p>
          </div>

          <div className="solution">
            <img src={solution} alt="solution" />
            <p>
              Está na hora de fazer{' '}
              <span className="highlight">sua empresa decolar</span>. Com nossas
              soluções abrangentes em{' '}
              <span className="highlight">
                marketing digital, terceirização financeira, terceirização de
                departamento pessoal e assistência jurídica
              </span>
              , oferecemos a você uma vantagem competitiva imbatível. Não espere
              mais. Venha nos conhecer e descubra como podemos{' '}
              <span className="highlight">impulsionar seu negócio</span> rumo ao
              sucesso que você sempre buscou.
            </p>
          </div>
        </Container>
      </MainContainer>
    </>
  );
};

export default Home;
